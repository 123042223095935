import { asyncRequire } from '~lib/storefront';

const defaultFreeShippingValue = {
    enabled: false,
    message: ''
};

export default function () {
    return {
        freeShipping: defaultFreeShippingValue,

        async init() {
            const [customerData] = await asyncRequire([
                'Magento_Customer/js/customer-data'
            ]);
            const cart = customerData.get('cart');

            this.setFreeShippingData(cart());

            cart.subscribe(function (cart) {
                this.setFreeShippingData(cart);
            }, this);
        },

        setFreeShippingData(cartData) {
            const freeShippingData =
                (cartData.delivery && cartData.delivery.freeShipping) ||
                defaultFreeShippingValue;

            this.freeShipping = freeShippingData;
        }
    };
}
