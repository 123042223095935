import { scrollToElement } from '../../helpers/utils';

export default function ({ productReviewUrl = '' }) {
    return {
        productReviewUrl,
        content: '',
        isEndOfList: false,
        currentPage: 0,

        init() {
            this.initReviews(this.productReviewUrl);
        },

        async getReviews(url) {
            const response = await fetch(url);

            if (this.isEndOfList) {
                this.currentPage = 0;
                this.isEndOfList = false;
                this.content = await response.text();
            } else {
                this.content += await response.text();
            }
        },

        async initReviews(url) {
            await this.getReviews(url);
            this.currentPage += 1;

            const actions = document.querySelectorAll(
                '[data-role="product-review-pager"]'
            );

            if (this.currentPage > actions.length && actions.length > 0) {
                this.isEndOfList = true;
                return;
            }

            if (actions.length > 0) {
                const action = actions[this.currentPage - 1];

                action.addEventListener('click', async (event) => {
                    event.preventDefault();
                    this.initReviews(action.getAttribute('href'));
                });
            }
        },

        collapseReviews() {
            scrollToElement('#customer-reviews', {
                offset: -200
            });
            this.initReviews(this.productReviewUrl);
        }
    };
}
