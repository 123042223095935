export default function ({ initValue, options }) {
    return {
        options,
        initValue,
        selectedOption: {
            label: '',
            value: ''
        },

        init() {
            const searchParams = new URLSearchParams(window.location.href);

            if (searchParams.has('product_list_order')) {
                this.selectedOption = this.options.find(
                    (option) =>
                        option.value === searchParams.get('product_list_order')
                );
            } else {
                this.selectedOption = this.options.find(
                    (option) => option.value === this.initValue
                );
            }
            this.setupWatchers();
        },

        setupWatchers() {
            this.$watch('selectedOption', () => {
                this.insertParam(
                    'product_list_order',
                    this.selectedOption?.value
                );
            });
        },

        setOption(option) {
            if (option.value === this.selectedOption.value) return;
            this.selectedOption = option;
        },

        insertParam(keyValue, selectValue) {
            const key = encodeURIComponent(keyValue);
            const value = encodeURIComponent(selectValue);
            const kvp = document.location.search.substr(1).split('&');
            let i = 0;

            for (; i < kvp.length; i++) {
                if (kvp[i].startsWith(key + '=')) {
                    let pair = kvp[i].split('=');
                    pair[1] = value;
                    kvp[i] = pair.join('=');
                    break;
                }
            }

            if (i >= kvp.length) {
                kvp[kvp.length] = [key, value].join('=');
            }

            document.location.search = kvp.join('&');
        }
    };
}
