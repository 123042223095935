import { debounce } from '../../helpers/utils';
import { asyncRequire } from '~lib/storefront';

export default function () {
    return {
        total: '',
        checkoutHref: '',

        init() {
            this.initTotals();
            this.setButtonLink();
            this.scrollHandler();
        },

        scrollHandler() {
            document.addEventListener(
                'scroll',
                debounce(this.setTotalsBehaviour, 100, this)
            );
        },

        setTotalsBehaviour() {
            if (!this.isMobile()) return false;

            const vTotals = document.querySelector('.cart-summary');
            const vHeight = vTotals.offsetHeight;
            const vOffset = vTotals.offsetTop;
            const breakpoint = vOffset + vHeight;
            const root = this.$refs.root;
            const isActive = root.classList.contains(
                'cart-totals-mobile--active'
            );

            if (window.scrollY > breakpoint) {
                if (!isActive) {
                    this.appendBlock(root);
                }
            } else {
                if (isActive) {
                    this.hideBlock(root);
                }
            }
        },

        appendBlock(root) {
            root.classList.add('cart-totals-mobile--active');
        },

        hideBlock(root) {
            root.classList.remove('cart-totals-mobile--active');
        },

        isMobile() {
            return this.$store.mq.isMobile();
        },

        async initTotals() {
            const [quote] = await asyncRequire([
                'Magento_Checkout/js/model/quote'
            ]);

            this.setTotals(quote.totals().base_grand_total);

            quote.totals.subscribe(function (totals) {
                this.setTotals(totals.base_grand_total);
            }, this);
        },

        setTotals(total) {
            const { pattern, precision, decimalSymbol } =
                window.checkoutConfig.priceFormat;

            if (!pattern || !precision) return;

            this.total = pattern
                .replace('%s', parseFloat(total).toFixed(precision))
                .replace('.', decimalSymbol);
        },

        setButtonLink() {
            this.checkoutHref = window.checkout.checkoutUrl;
        }
    };
}
