export function scrollToElement(selector, { offset }) {
    const element = document.querySelector(selector);
    const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
    const url = window.location.href.split('#')[0];
    history.pushState({}, null, url + selector);
    window.scrollTo({ top: y, behavior: 'smooth' });
}

export function debounce(callback, time, context) {
    let timeout;

    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            timeout = null;
            callback.apply(context, arguments);
        }, time);
    };
}
