export default function () {
    return {
        isOpen: false,

        init() {
            this.isOpen = this.$store.mq.isMobile();

            this.$watch('$store.mq.isMobile()', () => {
                this.isOpen = this.$store.mq.isMobile();
            });
        }
    };
}
