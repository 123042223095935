import { registerComponent, registerStore } from '~lib/storefront';
import collapsible from '~/adr/alpine/components/ui/collapsible';
import messages from '~/adr/alpine/components/ui/messages';
import cookiesConsent from '~/adr/alpine/components/ui/cookies-consent';
import slider from '~/adr/alpine/components/ui/slider';
import subscriptionInpostSelect from '~/adr/alpine/components/subscription/inpost-select';
import freeDelivery from '~/alpine/components/cart/free-delivery';
import wishlistStatus from '~/alpine/components/product/wishlist-status';
import totalsMobile from '~/alpine/components/cart/totals-mobile';
import toolbarSorter from '~/alpine/components/listing/toolbar-sorter';
import filterOption from '~/alpine/components/listing/filter-option';
import productGallery from '~/alpine/components/product/product-gallery';
import reviews from '~/alpine/components/product/reviews';
import mq from '~/adr/alpine/stores/mq';
import mageCacheStorage from '~/alpine/stores/mage-cache-storage';

registerComponent('productGallery', productGallery);
registerComponent('reviews', reviews);
registerComponent('toolbarSorter', toolbarSorter);
registerComponent('filterOption', filterOption);
registerComponent('totalsMobile', totalsMobile);
registerComponent('slider', slider);
registerComponent('collapsible', collapsible);
registerComponent('freeDelivery', freeDelivery);
registerComponent('wishlistStatus', wishlistStatus);
registerComponent('messages', messages);
registerComponent('cookiesConsent', cookiesConsent);
registerComponent('subscriptionInpostSelect', subscriptionInpostSelect);

registerStore('mq', mq);
registerStore('mageCacheStorage', mageCacheStorage);
